@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
    .hoverEffect {
        @apply cursor-pointer hover:bg-gray-200 rounded-full p-3 transition duration-500 ease-out
    }
    .hoverEffect2 {
        @apply cursor-pointer w-[85px] px-0 py-2 md:p-3 md:w-[93px] hover:bg-gray-200 rounded-md  text-center  transition duration-500 ease-out
    }
    .boten span {
        @apply text-sm bg-blue-400 text-white p-1 px-2 rounded-sm
    }
    .underline2 {
        @apply absolute bottom-[-1px] left-0 right-0 h-[1px] bg-red-600
    }
}